import React, { useEffect, useRef } from "react";
import Link from "next/link";

import { useHandleClickOutside } from "@/hooks";
import { EditionType } from "@/types/epaper";

interface EpaperEditionsSidebarProps {
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editions: EditionType[];
}

export default function EpaperEditionsSidebar({
  setIsSidebarOpen,
  editions,
}: EpaperEditionsSidebarProps) {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  useHandleClickOutside(sidebarRef, () => setIsSidebarOpen(false));

  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <>
      <div className="md:hidden fixed inset-0 bg-[#00000080]"></div>
      <div
        ref={sidebarRef}
        className="md:hidden fixed top-0 left-0 bg-[#333] w-[80vw] min-h-full h-full overflow-auto"
      >
        <div className="flex items-center justify-between p-4">
          <span className="text-lg font-medium text-white">Editions</span>
          {/* <button className="text-3xl text-white" onClick={() => setIsSidebarOpen(false)}>
      <MdClose />
    </button> */}
        </div>

        <div className="mt-1">
          {editions?.map((edition, index) => {
            return (
              <div key={index} className="border-b border-[#4a4a4a]">
                <Link href={`/epaper/${edition?.editionType}/${edition?.slug}`}>
                  <span className="block px-4 py-3 text-[13px] text-white uppercase font-medium">
                    {edition?.name}
                  </span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
