"use client";

import { NewspaperType } from "@/types/epaper";
import { getImageUrl } from "@/utils/getImageUrl";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
interface EpaperCardProps {
  date: string;
  newsPaper: NewspaperType;
  index: number;
}
const EpaperCard: FC<EpaperCardProps> = ({ date, newsPaper, index }) => {
  const [src, setSrc] = useState(
    getImageUrl(
      newsPaper?.thumbnail?.sizes?.sd?.filename ||
        newsPaper?.pages[0]?.page?.sizes?.ld?.filename ||
        "",
    ),
  );

  useEffect(() => {
    const lowResUrl = getImageUrl(
      newsPaper?.thumbnail?.sizes?.sd?.filename ||
        newsPaper?.pages[0]?.page?.sizes?.ld?.filename ||
        "",
    );
    const highResUrl = getImageUrl(
      newsPaper?.thumbnail?.sizes?.ld?.filename ||
        newsPaper?.pages[0]?.page?.sizes?.ld?.filename ||
        "",
    );

    setSrc(lowResUrl);
    const image = new Image();
    image.src = highResUrl;

    image.onload = () => {
      setSrc(highResUrl);
    };
  }, [newsPaper]);

  
  return (
    <Link
      href={`/epaper/newspaper/${newsPaper?.editionId?.slug}/${date}`}
      key={index}
      aria-label={`View e-paper for ${newsPaper?.editionId?.name} on ${date}`}
    >
      <div className="h-[340px] overflow-hidden border border-b-0 border-[#efefef] dark:border-darkBorderColor">
        <img
          src={src}
          alt={`Epaper ${newsPaper?.editionId?.name} on ${date}`}
          className="object-cover object-left-top w-full"
          loading="lazy"
        />
      </div>
      <div className="bg-[#a70101] border-b-4 border-b-[#000] dark:border-b-[#fff]">
        <span className="block py-2 font-medium text-center text-white">
          {newsPaper?.editionId?.name}
        </span>
      </div>
    </Link>
  );
};

export default EpaperCard;
