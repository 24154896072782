"use client";

import { useState } from "react";
import Link from "next/link";
import { FaHome } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";

import { EditionType } from "@/types/epaper";
import EpaperEditionsSidebar from "./EpaperEditionsSidebar";

interface EpaperEditionsHeaderProps {
  editions: EditionType[];
}

export default function EpaperEditionsHeader({ editions }: EpaperEditionsHeaderProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="bg-white dark:bg-[#6d6d6d] dark:text-white shadow-md py-2">
      <div className="hidden container md:flex flex-wrap items-center h-full gap-3 divide-x divide-[#b9540a] dark:divide-[#000]">
        <Link href={"/epaper"} aria-label="Go to epaper page">
          <FaHome className="text-xl" />
        </Link>
        {editions?.map((edition, index) => {
          return (
            <div key={index} className="pl-3">
              <Link href={`/epaper/${edition?.editionType}/${edition?.slug}`}>
                <span className="text-[12px] uppercase font-medium">{edition?.name}</span>
              </Link>
            </div>
          );
        })}
      </div>
      <div className="container md:hidden flex items-center justify-between">
        <button
          className=" text-[15px] flex items-center gap-2"
          onClick={() => setIsSidebarOpen(true)}
        >
          <IoMenu size={35} /> Editions
        </button>

        {isSidebarOpen && (
          <EpaperEditionsSidebar setIsSidebarOpen={setIsSidebarOpen} editions={editions} />
        )}

        <Link href={"/epaper"} aria-label="Go to epaper page">
          <FaHome className="text-xl" />
        </Link>
      </div>
    </div>
  );
}
